import React from "react";
import PropTypes from "prop-types";
import Image from "gatsby-image";
import styled from "styled-components";
import Grid from "./Grid";
import theme from "../theme";

const GalleryImage = styled(Image)`
  background: ${theme.contrast};
  border: 5px solid ${theme.contrast};
  border-radius: ${theme.borderRadius};
  box-sizing: border-box;
  margin: 0.15vw 1vw;
  height: 180px;
  width: 170px;
`;

const Gallery = ({ media }) => (
  <Grid justify="center" align="center">
    {media.map((pic) => (
      <GalleryImage
        {...pic}
        key={pic.contentful_id}
        imgStyle={{
          objectFit: "contain",
        }}
      />
    ))}
  </Grid>
);

Gallery.propTypes = {
  media: PropTypes.arrayOf(
    PropTypes.shape({
      contentful_id: PropTypes.string,
      title: PropTypes.string,
      fluid: PropTypes.object,
    })
  ).isRequired,
};

export default Gallery;
