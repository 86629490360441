import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { get } from "lodash";
import App from "../components/App";
import HeroInterior, { HeroSubTitle } from "../components/HeroInterior";
import Panel from "../components/Panel";
import CallToAction from "../components/CallToAction";
import SEO from "../components/SEO";
import Container from "../components/Container";
import Grid, { Third } from "../components/Grid";
import Gallery from "../components/Gallery";
import RichText from "../components/RichText";
import Wrapper from "../components/Wrapper";
import CourtForms from "../components/CourtForms";
import { Label } from "../components/AuthorWidget";
import EmbeddedVideo, { getVideoHostLink } from "../components/EmbeddedVideo";
import FeaturedTestimonial from "../components/FeaturedTestimonial";
import { TwoColumnList } from "../components/List";
import CardResource from "../components/CardResource";
import Testimonial from "../components/Testimonial";
import Values from "../components/Values";
import Badge from "../components/Badge";

function isPage({ slug }, name, renderer) {
  if (slug !== name) {
    return null;
  }

  return renderer;
}

const Page = ({ data }) => {
  const { contentfulPage } = data;
  const {
    header,
    panels,
    cards,
    photo,
    body,
    galleries,
    testimonials,
    videoTestimonials,
    features,
    resources,
    callToAction: cta,
    featuresLeadIn,
  } = contentfulPage;

  const hasValues = () =>
    Array.isArray(features) ? features.some((item) => item.icon) : false;

  const hasChecklist = () =>
    Array.isArray(features) ? features.every((item) => !item.icon) : false;

  return (
    <App>
      <SEO {...contentfulPage} />
      {header && (
        <HeroInterior
          {...header}
          slug={contentfulPage.slug}
          version={header.version.toLowerCase()}
          title={header.title}
          subtitle={get(header, "subtitle.subtitle")}
        />
      )}
      {body ? (
        JSON.stringify(body).length < 1000 ? (
          <Container small margin>
            <RichText body={body} center />
          </Container>
        ) : (
          <Container margin>
            <RichText body={body} />
          </Container>
        )
      ) : null}
      {cards && (
        <Container margin>
          <Grid style={{ marginTop: "-4rem" }}>
            {cards.map((card) => (
              <Third key={card.contentful_id}>
                <Label>{card.title}</Label>
                <RichText body={card.description} />
              </Third>
            ))}
          </Grid>
        </Container>
      )}
      {panels && (
        <Wrapper variant="muted">
          <Container large>
            {panels.map((panel, i) => (
              <Panel
                {...panel}
                transparent={!body}
                key={panel.contentful_id}
                reverse={i % 2}
              />
            ))}
          </Container>
        </Wrapper>
      )}
      {photo && (
        <div
          style={{
            backgroundColor: "rgb(251,251,251)",
            padding: "4vh 3vw",
            position: "relative",
          }}
        >
          {isPage(
            contentfulPage,
            "careers",
            <Badge alt="Best Place to Work" src="/BestPlaceToWork.png" />
          )}
          <Container large style={{ textAlign: "center" }}>
            <h2 style={{ marginBottom: "1rem" }}>{photo.title}</h2>
            <HeroSubTitle>{photo.description}</HeroSubTitle>
            {hasChecklist() && (
              <Container small>
                <TwoColumnList>
                  <ul>
                    {features.map((feature) => (
                      <li key={features.contentful_id}>{feature.title}</li>
                    ))}
                  </ul>
                </TwoColumnList>
              </Container>
            )}
            <img
              style={{ marginTop: "2rem" }}
              src={get(photo, "file.url")}
              alt={photo.title}
            />
          </Container>
        </div>
      )}
      {galleries ? (
        <Wrapper>
          {galleries.map((gallery, i) => (
            <Container large key={gallery.contentful_id}>
              <h2 style={{ textAlign: "center" }}>{gallery.title}</h2>
              <Gallery {...gallery} />
              {i !== galleries.length - 1 ? (
                <div
                  style={{
                    width: "100%",
                    height: 1,
                    margin: "3rem 0 1rem",
                    backgroundColor: "#182238",
                  }}
                />
              ) : null}
            </Container>
          ))}
        </Wrapper>
      ) : null}
      {hasValues() && (
        <Values data={features}>
          <RichText body={featuresLeadIn} />
        </Values>
      )}
      {Array.isArray(testimonials) && (
        <Wrapper variant="darkMuted">
          {testimonials.map((testimonial, i) =>
            contentfulPage.slug === "careers" || i > 0 ? (
              <Testimonial key={testimonial.contentful_id} {...testimonial} />
            ) : (
              <FeaturedTestimonial
                isWord={contentfulPage.slug === "word-lx"}
                key={testimonial.contentful_id}
                {...testimonial}
              />
            )
          )}
          {Array.isArray(videoTestimonials) && (
            <Grid align="center" justify="center" style={{ gap: "1rem" }}>
              {videoTestimonials.map((testimonial, i) => (
                <div
                  key={i}
                  style={{
                    flex: 1,
                    minWidth: 350,
                  }}
                >
                  <EmbeddedVideo src={getVideoHostLink(testimonial.videoUrl)} />
                </div>
              ))}
            </Grid>
          )}
        </Wrapper>
      )}
      {resources && (
        <Container large>
          <Grid>
            {resources.map((resource) => (
              <CardResource half key={resource.contentful_id} {...resource} />
            ))}
          </Grid>
        </Container>
      )}
      {isPage(
        contentfulPage,
        "inform",
        <Wrapper variant="darkMuted">
          <Container>
            <CourtForms />
          </Container>
        </Wrapper>
      )}
      {cta && <CallToAction {...cta} />}
    </App>
  );
};

Page.propTypes = {
  data: PropTypes.shape({
    contentfulPage: PropTypes.object,
  }).isRequired,
};

export default Page;

export const query = graphql`
  fragment PanelDetail on ContentfulPanel {
    contentful_id
    title
    leader {
      leader
    }
    body {
      childContentfulRichText {
        html
      }
    }
    roundedImage
    image {
      alt: title
      fluid(maxWidth: 750) {
        ...GatsbyContentfulFluid
      }
    }
  }

  query getPageByID($contentful_id: String) {
    contentfulPage(contentful_id: { eq: $contentful_id }) {
      title
      slug
      description {
        description
      }
      featuresLeadIn {
        childContentfulRichText {
          html
        }
      }
      features {
        title
        icon {
          alt: title
          fluid(maxWidth: 55) {
            ...GatsbyContentfulFluid
          }
        }
      }
      photo {
        title
        description
        file {
          url
        }
      }
      header {
        ...HeroDetail
      }
      panels {
        ...PanelDetail
      }
      body {
        childContentfulRichText {
          html
        }
      }
      cards {
        ...CardDetail
      }
      galleries {
        title
        contentful_id
        media {
          alt: title
          description
          contentful_id
          fluid(maxWidth: 210) {
            ...GatsbyContentfulFluid
          }
        }
      }
      videoTestimonials {
        ...TestimonialDetail
      }
      testimonials {
        ...TestimonialDetail
      }
      callToAction {
        ...CallToActionDetail
      }
      resources {
        ... on ContentfulDownloadable {
          title
          link
          type
          internal {
            type
          }
        }
      }
    }
  }
`;
