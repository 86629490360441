import styled from "styled-components";

const Badge = styled.img`
  position: absolute;
  width: 85px;
  right: 2rem;
  top: 0;

  @media (max-width: 1125px) {
    position: relative;
    top: auto;
    right: auto;
    display: block;
    margin: 2rem auto;
  }
`;

export default Badge;
