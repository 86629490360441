import React from 'react';
import { groupBy } from 'lodash';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';
import {
  FiMinus,
  FiPlus,
} from 'react-icons/fi';
import RichText from './RichText';
import theme from '../theme';

const query = graphql`
  query {
    forms: allContentfulCourtForm(sort: {fields: order, order: ASC}) {
      nodes {
        contentful_id
        provinceRegion
        title
        type
        body {
          childContentfulRichText {
            html
          }
        }
      }
    }
  }
`;

export const AccordionWrapper = styled(AccordionItem)`
  background-color: ${theme.contrast};
  box-shadow: 3px 4px 12px ${theme.dark}20;
  margin-bottom: .15rem;
  padding: 2vh 3vw;
`;

const AccordionContent = styled(AccordionItemPanel)`
  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style: none;
    margin: 1rem 0 0;
    padding: 0;
  }

  li {
    background-color: ${theme.muted};
    box-sizing: border-box;
    color: ${theme.tertiary};
    display: inline-block;
    padding: 2vh;
    margin: .5%;
    transition: background-color 500ms;
    width: 24%;

    &:hover {
      background-color: ${theme.darkMuted};
    }

    p {
      font-size: 0.833em;
    }

    @media (max-width: 768px){
      width: 49%;
    }
  }
`;

export const AccordionTitle = styled(AccordionItemButton)`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  outline: 0;
  transition: color 500ms;

  &:hover {
    color: ${theme.primary};
  }
`;

export const AccordionIntroductionTitle = styled.h3`
  margin: 0 0 1rem;

  &:not(:first-child){
    margin-top: 2rem;
  }
`;

function CourFormRows({ items }) {
  return items.map((acc) => {
    const {
      contentful_id: id,
      title,
      body,
    } = acc;

    return (
      <AccordionWrapper key={id}>
        <AccordionItemHeading>
          <AccordionTitle>
            {title}
            <AccordionItemState>
              {({ expanded }) => expanded
                ? <FiMinus />
                : <FiPlus />
              }
            </AccordionItemState>
          </AccordionTitle>
        </AccordionItemHeading>
        <AccordionContent>
          <RichText body={body} />
        </AccordionContent>
      </AccordionWrapper>
    );
  });
}

function CourtFormSub({ val }) {
  const groups = groupBy(val, 'type');
  const ordered = {};
  Object.keys(groups).sort().forEach((key) => {
    ordered[key] = groups[key];
  });

  return Object.keys(ordered).length > 1
    ? Object.entries(ordered).map(([key, items]) => (
      <div key={key}>
        <h3 style={{ fontSize: '1.2em', marginTop: '2rem' }}>{key}</h3>
        <CourFormRows items={items} />
      </div>
    ))
    : <CourFormRows items={val} />;
}

export default function CourtForms() {
  return (
    <StaticQuery
      query={query}
      render={({ forms }) => {
        const { nodes } = forms;
        const sortedForms = groupBy(nodes, 'provinceRegion');

        return Object
          .entries(sortedForms)
          .map(([key, val = []]) => (
            <React.Fragment>
              <AccordionIntroductionTitle>
                {`${key} Forms`}
              </AccordionIntroductionTitle>
              <Accordion key={key} allowZeroExpanded>
                <CourtFormSub val={val} />
              </Accordion>
            </React.Fragment>
          ));
      }}
    />
  );
}
