import React from "react";
import PropTypes from "prop-types";
import Image from "gatsby-image";
import Container from "./Container";
import Grid, { Half } from "./Grid";
import styled from "styled-components";
import { CallToActionWrapper } from "./CallToAction";
import theme from "../theme";

const ValueWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 1rem;
  width: 50%;
  padding-right: 0.5rem;

  @media (max-width: 526px) {
    width: 100%;
  }

  .gatsby-image-wrapper {
    margin-right: 1rem;
    min-width: 35px;
    width: 35px;
  }
`;

const BodyWrapper = styled.div`
  * {
    color: ${theme.contrast};
  }
`;

const Values = ({ children, data }) =>
  Array.isArray(data) ? (
    <CallToActionWrapper>
      <Container>
        <Grid>
          <Half>
            <BodyWrapper>{children}</BodyWrapper>
          </Half>
          <Half>
            <Grid>
              {data.map((feature, idx) => (
                <ValueWrapper key={`value-${idx}`}>
                  <Image {...feature.icon} />
                  {feature.title}
                </ValueWrapper>
              ))}
            </Grid>
          </Half>
        </Grid>
      </Container>
    </CallToActionWrapper>
  ) : null;

Values.propTypes = {
  children: PropTypes.node,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      icon: PropTypes.object,
    })
  ),
};

export default Values;
